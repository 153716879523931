// components/RoomTypeCard.js
import React, { useEffect } from "react";

const RoomTypeCard = ({ room, onDelete, onEdit }) => {
  // Destructure room properties with default values
  const {
    id = room.id,
    roomLabel = room.typeName || 'Standard Room',
    rent = room.monthlyRent || 0,
    deposit = room.securityDeposit || 0,
    gender = room.selectedGender || "Unknown",
    occupancy = room.selectedOccupancy || "Single",
    amenities = room.selectedAmenities || [],
    roomImages = room.selectedPhotos || [],
    beds = room.beds || 0,
    rooms = room.rooms || 0
  } = room || {};

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  // Handle edit click
  const handleEdit = () => {
    if (onEdit) {
      onEdit(room);
    }
  };

  // Handle delete click
  const handleDelete = () => {
    if (onDelete && window.confirm('Are you sure you want to delete this room type?')) {
      onDelete(id);
    }
  };

  useEffect(() => { }, [room]);

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <div className="w-full p-4">
        <h2 className="text-lg font-medium mb-6 text-gray-800">Room Type Details</h2>

        <div className="flex flex-col md:flex-row border shadow-md rounded-2xl p-4 sm:p-6">
          {/* Room Images Section */}
          <div className="w-full md:w-[49%] rounded-xl overflow-hidden mb-4 md:mb-0 md:mr-4">
            {roomImages && roomImages.length > 0 ? (
              <div className="relative group">
                <img
                  src={URL.createObjectURL(roomImages[0])}
                  alt={`${roomLabel} Room`}
                  className="w-full h-48 sm:h-64 md:h-96 object-cover rounded-lg transition duration-300 group-hover:opacity-95"
                />
                {roomImages.length > 1 && (
                  <div className="absolute bottom-2 right-2 bg-black bg-opacity-60 text-white px-2 py-1 rounded text-sm">
                    +{roomImages.length - 1} more
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full h-48 sm:h-64 md:h-96 bg-gray-200 flex items-center justify-center rounded-lg">
                <span className="text-gray-500 text-sm font-medium">No Image Available</span>
              </div>
            )}
          </div>

          {/* Room Details Section */}
          <div className="flex-1 flex flex-col justify-between ml-0 md:ml-4">
            {/* Header with Title and Actions */}
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  {roomLabel}
                </h3>
                <div className="flex flex-wrap gap-2 mt-2">
                  {/* Occupancy Badge */}
                  <div className="flex items-center bg-blue-100 text-blue-600 text-xs font-medium px-3 py-1 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                    {occupancy} ({beds} {beds === 1 ? 'bed' : 'beds'})
                  </div>

                  {/* Gender Badge */}
                  <span className="bg-purple-100 text-purple-600 text-xs font-medium px-3 py-1 rounded-full">
                    {gender}
                  </span>

                  {/* Rooms Count Badge */}
                  <span className="bg-green-100 text-green-600 text-xs font-medium px-3 py-1 rounded-full">
                    {rooms} {rooms === 1 ? 'room' : 'rooms'} available
                  </span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-2">
                <button
                  onClick={handleEdit}
                  className="bg-blue-50 p-2 rounded-full hover:bg-blue-100 transition"
                  title="Edit room"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-blue-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleDelete}
                  className="bg-red-50 p-2 rounded-full hover:bg-red-100 transition"
                  title="Delete room"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-red-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Pricing Information */}
            <div className="bg-gray-50 rounded-lg p-4 mb-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Monthly Rent</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {formatCurrency(rent)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Security Deposit</p>
                  <p className="text-lg font-semibold text-gray-900">
                    {formatCurrency(deposit)}
                  </p>
                </div>
              </div>
            </div>

            {/* Amenities Section */}
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Room Amenities
              </h4>
              <div className="flex flex-wrap gap-2">
                {amenities && amenities.length > 0 ? (
                  amenities.map((amenity, index) => (
                    <span
                      key={index}
                      className="bg-gray-100 text-gray-700 text-xs font-medium px-3 py-1 rounded-full"
                    >
                      {amenity}
                    </span>
                  ))
                ) : (
                  <span className="text-gray-500 text-sm">
                    No amenities listed
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomTypeCard;