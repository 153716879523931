import { useState, useEffect } from 'react';
import { createProperty } from './propertyService';

export const usePropertyForm = () => {
    const [formData, setFormData] = useState({
        propertyData: null,
        images: null,
        rooms: [],
        propertyImages: [],
        roomTypeImages: []
    });
    const [trig, setTrig] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleSubmitOver = (event) => {
        event.preventDefault();
        setModalOpen(true);
    };

    const handleConfirm = () => {
        handleSubmit();
        setModalOpen(false);
    };

    const handlePropertyFormChange = (propertyForm) => {
        const propertyData = {
            name: propertyForm.propertyName || 'Unnamed Property',
            city: propertyForm.selectedCity?.label || 'Default City',
            services: propertyForm.selectedServices || [],
            address: {
                buildingName: propertyForm.buildingName || 'Unknown Building',
                street: propertyForm.streetName || 'Unknown Street',
                locality: propertyForm.locality || 'Unknown Locality',
                city: propertyForm.selectedCity?.label || 'Default City',
                mapLocation: `${propertyForm.center?.lat || 0},${propertyForm.center?.lng || 0}`
            }
        };
        setFormData((prev) => ({ ...prev, propertyData: propertyData }));
    };

    const handleImagesFormChange = (imagesForm) => {
        const temp = imagesForm.images;
        const path = [];

        for (let i = 0; i < temp.length; i++) {
            temp[i] = new File([temp[i]], 'Property_' + (i + 1), { type: temp[i].type });
            path.push(temp[i].name);
        }

        const imagesData = {
            propertyImagePath: path,
            ownerDetails: {
                name: imagesForm.ownerName,
                phoneNumber: imagesForm.ownerPhone
            },
            referral: {
                name: imagesForm.referralName,
                phoneNumber: imagesForm.referralPhone
            }
        };

        setFormData(prev => ({
            ...prev,
            images: imagesData,
            propertyImages: temp
        }));
    };

    const handleRoomTypeSubmit = (roomTypes) => {
        const allrooms = [];
        const allimages = [];

        for (let i = 0; i < roomTypes.length; i++) {
            const temp = roomTypes[i].selectedPhotos;
            const paths = [];

            for (let k = 0; k < temp.length; k++) {
                temp[k] = new File([temp[k]], `room_${i + 1}_${k + 1}`, { type: temp[k].type });
                paths.push(temp[k].name);
            }

            const formattedRoom = {
                roomLabel: roomTypes[i].typeName || 'Standard Room',
                rent: parseFloat(roomTypes[i].monthlyRent.replace(/[^0-9.]/g, '')) || 0,
                deposit: parseFloat(roomTypes[i].securityDeposit?.replace(/[^0-9.]/g, '') || '1000'),
                gender: roomTypes[i].selectedGender || 'Unisex',
                occupancy: roomTypes[i].selectedOccupancy || 'Single',
                amenities: roomTypes[i].selectedAmenities || [],
                roomImagePath: paths,
            };
            allrooms[i] = formattedRoom;
            allimages.push(...temp);
        }

        setFormData((prev) => ({
            ...prev,
            rooms: allrooms,
            roomTypeImages: allimages,
        }));
    };

    const validateFormData = (formData) => {
        const errors = [];

        if (!formData.propertyData?.name) {
            errors.push("Property name is required");
        }
        if (!formData.propertyData?.city) {
            formData.propertyData.city = 'Default City';
        }
        if (!formData.rooms.every(room => room.deposit !== undefined)) {
            errors.push("All rooms must have a security deposit");
        }

        return errors;
    };

    const prepareFormData = (formData) => {
        const submitFormData = new FormData();

        const propertyData = {
            ...formData.propertyData,
            ownerDetails: formData.images?.ownerDetails,
            referral: formData.images?.referral,
            propertyImagePath: formData.images?.propertyImagePath,
            roomTypes: formData.rooms
        };

        submitFormData.append('propertyData', JSON.stringify(propertyData));

        if (formData.propertyImages) {
            formData.propertyImages.forEach((image) => {
                submitFormData.append('propertyImages', image);
            });
        }

        formData.roomTypeImages.forEach(image => {
            submitFormData.append('roomTypeImages', image);
        });

        return submitFormData;
    };

    const handleSubmit = async () => {
        try {
            const validationErrors = validateFormData(formData);
            if (validationErrors.length > 0) {
                setError(validationErrors.join("\n"));
                return;
            }

            setIsSubmitting(true);
            setError(null);
            setSuccess(false);

            const submitFormData = prepareFormData(formData);
            await createProperty(submitFormData);

            setFormData({
                propertyData: null,
                images: null,
                rooms: [],
                propertyImages: [],
                roomTypeImages: []
            });
            setTrig(!trig);
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false);
            }, 5000);
            setSuccess(true);

        } catch (err) {
            setError(err.response?.data?.error || 'Failed to submit property');
            console.error('Error creating property:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
    }, [isModalOpen]);

    return {
        formData,
        isSubmitting,
        error,
        success,
        handlePropertyFormChange,
        handleImagesFormChange,
        handleRoomTypeSubmit,
        handleSubmitOver,
        handleSubmit,
        handleConfirm,
        isModalOpen,
        setModalOpen,
        showModal,
        setShowModal,
        trig
    };
};