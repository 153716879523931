import React, { useEffect, useCallback } from 'react';


const ConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title = "Confirm Submission",
  message = "Are you sure you want to submit the Property?"
}) => {
  const handleEscapeKey = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  // Handle keyboard events
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      // Prevent scrolling when modal is open
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, handleEscapeKey]);

  // Handle click outside
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md"
      onClick={handleBackdropClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div 
        className={`
          bg-white p-6 rounded-lg shadow-lg w-96
          transform transition-all duration-300 ease-in-out
          ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}
        `}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 
            id="modal-title"
            className="text-lg font-bold"
          >
            {title}
          </h2>
          
        </div>

        <p className="mt-2 text-gray-600">{message}</p>

        <div className="mt-6 flex justify-end space-x-4">
        <button
            onClick={onConfirm}
            className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white 
                     transition-colors duration-200 focus:outline-none focus:ring-2 
                     focus:ring-blue-400 focus:ring-opacity-50"
            autoFocus
          >
            Yes, Submit
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 rounded bg-gray-100 hover:bg-gray-200 text-gray-700 
                     transition-colors duration-200 focus:outline-none focus:ring-2 
                     focus:ring-gray-400 focus:ring-opacity-50"
          >
            Cancel
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;