import React, { useState, useEffect } from "react";
import RoomTypeCard from "./RoomTypeCard";

const occupancyBedCount = {
  Single: 1,
  Double: 2,
  Triple: 3,
  Quadruple: 4,
  Quintuple: 5,
  ">5": 6,
};

const availableAmenities = [
  "Attached Bathroom",
  "AC",
  "Attached Balcony",
  "Storage Shelf",
  "Desert Cooler",
  "Kitchen",
  "Gym",
  "Parking",
  "Swimming Pool",
  "WiFi",
];

const RoomDetailsForm = ({ onRoomSubmit, trig }) => {
  const [forms, setForms] = useState([createFormState()]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  // Function to create a new form state
  function createFormState() {
    return {
      id: Date.now(),
      typeName: "",
      monthlyRent: "",
      securityDeposit: "",
      selectedGender: "Female",
      selectedOccupancy: "Double",
      selectedPhotos: [],
      selectedAmenities: [],
      beds: 2,
      rooms: 0,
    };
  }

  useEffect(() => {
    setForms([createFormState()])
    setRoomTypes([])
    setValidationErrors({})

  }, [trig])

  // Validation function
  const validateForm = (form) => {
    const errors = {};
    if (!form.typeName.trim()) {
      errors.typeName = "Room type name is required";
    }
    if (!form.monthlyRent.trim()) {
      errors.monthlyRent = "Monthly rent is required";
    } else if (isNaN(parseFloat(form.monthlyRent.replace(/[^0-9.]/g, "")))) {
      errors.monthlyRent = "Invalid rent amount";
    }
    if (!form.securityDeposit.trim()) {
      errors.securityDeposit = "Security deposit is required";
    } else if (isNaN(parseFloat(form.securityDeposit.replace(/[^0-9.]/g, "")))) {
      errors.securityDeposit = "Invalid deposit amount";
    }
    if (form.selectedPhotos.length < 3) {
      errors.photos = "Please add at least 3 pictures";
    }
    return errors;
  };

  // Function to handle form data updates
  const handleFormUpdate = (id, field, value) => {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id ? { ...form, [field]: value } : form
      )
    );
    // Clear validation error for the field being updated
    setValidationErrors((prev) => ({
      ...prev,
      [field]: undefined,
    }));
  };

  // Function to handle occupancy selection and update bed count
  const handleOccupancySelect = (id, occupancy) => {
    const bedCount = occupancyBedCount[occupancy] || 0;
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? { ...form, selectedOccupancy: occupancy, beds: bedCount }
          : form
      )
    );
  };

  // Function to handle gender selection
  const handleGenderSelect = (id, gender) => {
    handleFormUpdate(id, "selectedGender", gender);
  };

  // Function to handle image upload
  const handleImageUpload = (id, event) => {
    const files = Array.from(event.target.files);
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? { ...form, selectedPhotos: [...form.selectedPhotos, ...files] }
          : form
      )
    );
    // Clear photo validation error
    setValidationErrors((prev) => ({
      ...prev,
      photos: undefined,
    }));
  };

  // Function to remove a photo
  const removePhoto = (id, index) => {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? {
            ...form,
            selectedPhotos: form.selectedPhotos.filter((_, i) => i !== index),
          }
          : form
      )
    );
  };

  // Function to toggle amenity selection
  const toggleAmenity = (id, amenity) => {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? {
            ...form,
            selectedAmenities: form.selectedAmenities.includes(amenity)
              ? form.selectedAmenities.filter((a) => a !== amenity)
              : [...form.selectedAmenities, amenity],
          }
          : form
      )
    );
  };

  // Function to submit form and add room type
  const submitForm = (form) => {
    const errors = validateForm(form);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Clear any existing validation errors
    setValidationErrors({});

    // Add to room types
    setRoomTypes((prevRoomTypes) => [...prevRoomTypes, form]);

    // Remove the submitted form from forms array
    setForms((prevForms) => prevForms.filter((f) => f.id !== form.id));
  };

  // Function to duplicate the form
  const duplicateForm = () => {
    setForms((prevForms) => [...prevForms, createFormState()]);
  };

  // Function to delete a specific form
  const deleteForm = (id) => {
    setForms((prevForms) => prevForms.filter((form) => form.id !== id));
  };

  const onDelete = (id) => {
    setRoomTypes((prevroom) => prevroom.filter((room, ind) => room.id !== id));
  }

  const onEdit = (room) => {
    setForms((prevForms) => [...prevForms, room])
    onDelete(room.id)
  }

  useEffect(() => {
    if (onRoomSubmit) {
      onRoomSubmit(roomTypes);
    }
  }, [onRoomSubmit, roomTypes])

  return (
    <div className="bg-white flex flex-col justify-center">
      {/* Render RoomTypeCard for each submitted form */}
      {roomTypes && roomTypes.map((room, index) => (
        <RoomTypeCard key={index} room={room} onDelete={onDelete} onEdit={onEdit} />
      ))}

      {forms.map((form) => (
        <div key={form.id} className="w-full mb-8 p-12 relative">
          <h2 className="text-lg font-medium mb-4 text-gray-800">Room Type Details</h2>

          {/* Icons for tick and cross */}
          <div className="absolute top-12 right-12 flex space-x-2">
            <button
              onClick={() => deleteForm(form.id)}
              className="bg-red-50 p-2 rounded-full hover:bg-red-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <button
              onClick={() => submitForm(form)}
              className="bg-green-50 p-2 rounded-full hover:bg-green-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-green-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </button>
          </div>

          <div className="border shadow-md rounded-2xl p-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Room Details Form */}
              <div className="border rounded-lg p-6 bg-gray-50">
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Type Name
                  </label>
                  <input
                    type="text"
                    value={form.typeName}
                    onChange={(e) =>
                      handleFormUpdate(form.id, "typeName", e.target.value)
                    }
                    placeholder="Enter your name here..."
                    className={`w-full rounded-lg border ${validationErrors.typeName
                      ? "border-red-500"
                      : "border-gray-300"
                      } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {validationErrors.typeName && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.typeName}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Monthly Rent
                  </label>
                  <input
                    type="text"
                    value={form.monthlyRent}
                    onChange={(e) =>
                      handleFormUpdate(form.id, "monthlyRent", e.target.value)
                    }
                    placeholder="₹ 12,999"
                    className={`w-full rounded-lg border ${validationErrors.monthlyRent
                      ? "border-red-500"
                      : "border-gray-300"
                      } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {validationErrors.monthlyRent && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.monthlyRent}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Gender <span className="text-gray-500 text-xs">Select one</span>
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {["Male", "Female", "Both"].map((gender) => (
                      <button
                        key={gender}
                        onClick={() => handleGenderSelect(form.id, gender)}
                        className={`px-4 py-2 rounded-full border ${form.selectedGender === gender
                          ? "bg-blue-100 text-blue-600"
                          : "bg-gray-100 text-gray-600"
                          } focus:outline-none`}
                      >
                        {gender}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Amenities{" "}
                    <span className="text-gray-500 text-xs">
                      Select all that apply
                    </span>
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {availableAmenities.map((amenity) => (
                      <button
                        key={amenity}
                        onClick={() => toggleAmenity(form.id, amenity)}
                        className={`px-3 py-1 rounded-full border ${form.selectedAmenities.includes(amenity)
                          ? "bg-green-100 text-green-600"
                          : "bg-gray-100 text-gray-600"
                          } focus:outline-none`}
                      >
                        {amenity}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Security Deposit, Occupancy, and Photos */}
              <div className="flex flex-col space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Security Deposit
                  </label>
                  <input
                    type="text"
                    value={form.securityDeposit}
                    onChange={(e) =>
                      handleFormUpdate(
                        form.id,
                        "securityDeposit",
                        e.target.value
                      )
                    }
                    placeholder="₹ 33,000"
                    className={`w-full rounded-lg border ${validationErrors.securityDeposit
                      ? "border-red-500"
                      : "border-gray-300"
                      } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {validationErrors.securityDeposit && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.securityDeposit}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Occupancy{" "}
                    <span className="text-gray-500 text-xs">Select one</span>
                  </label>
                  <div className="flex gap-2 flex-wrap">
                    {[
                      "Single",
                      "Double",
                      "Triple",
                      "Quadruple",
                      "Quintuple",
                      ">5",
                    ].map((occupancy) => (
                      <button
                        key={occupancy}
                        onClick={() =>
                          handleOccupancySelect(form.id, occupancy)
                        }
                        className={`px-4 py-2 rounded-full border ${form.selectedOccupancy === occupancy
                          ? "bg-blue-100 text-blue-600"
                          : "bg-gray-100 text-gray-600"
                          } focus:outline-none`}
                      >
                        {occupancy}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Photos{" "}
                    <span className="text-gray-500 text-xs">
                      Please add at least 3 pictures here
                    </span>
                  </label>
                  <div className="flex gap-2 mb-4">
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleImageUpload(form.id, e)}
                      className="hidden"
                      id={`upload-photos-${form.id}`}
                    />
                    <label
                      htmlFor={`upload-photos-${form.id}`}
                      className="cursor-pointer bg-blue-100 text-blue-600 text-sm font-medium px-4 py-2 rounded-full border border-dashed border-blue-300"
                    >
                      Upload Photos
                    </label>
                  </div>
                  {validationErrors.photos && (
                    <p className="mb-2 text-sm text-red-500">
                      {validationErrors.photos}
                    </p>
                  )}
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                    {form.selectedPhotos.map((photo, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt="Room"
                          className="rounded-lg w-full h-32 object-cover"
                        />
                        <button
                          onClick={() => removePhoto(form.id, index)}
                          className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm hover:bg-red-600 transition-colors"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Add New Type Button */}
      {!forms.length && (
        <div className="flex justify-center mt-6">
          <button
            onClick={duplicateForm}
            className="text-blue-600 font-medium px-6 py-2 rounded-full bg-blue-50 hover:bg-blue-100 transition-colors duration-200"
          >
            + Add new type
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomDetailsForm;