import React from "react";
import "./App.css";
import PropertyForm from "./components/PropertyForm";
import PropertyImages from "./components/PropertyImages";
import RoomDetailsForm from "./components/RoomDetailsForm";
import { usePropertyForm } from "./services/usePropertyForm";
import ConfirmationModal from "./modals/ConfirmationModal";
import SuccessModal from "./modals/SuccessModal";

function App() {
  const {
    error,
    success,
    isSubmitting,
    handlePropertyFormChange,
    handleImagesFormChange,
    handleRoomTypeSubmit,
    handleSubmitOver,
    isModalOpen,
    setModalOpen,
    showModal,
    setShowModal,
    handleConfirm,
    trig
  } = usePropertyForm();

  return (
    <>
      <div className="space-y-2">
        <PropertyForm onChange={handlePropertyFormChange} trig={trig} />
        <PropertyImages onChange={handleImagesFormChange} trig={trig} />
        <RoomDetailsForm onRoomSubmit={handleRoomTypeSubmit} trig={trig} />

        {error && (
          <div className="w-full flex justify-center px-4">
            <div className="bg-red-50 text-red-500 px-4 py-2 rounded-lg whitespace-pre-line">
              {error}
            </div>
          </div>
        )}

        {success && (
          <div className="w-full flex justify-center px-4">
            <div className="bg-green-50 text-green-500 px-4 py-2 rounded-lg">
              Property submitted successfully!
            </div>
          </div>
        )}

        <div className="w-full flex flex-row items-center justify-center my-6">
          <button
            onClick={handleSubmitOver}
            disabled={isSubmitting}
            className={`${isSubmitting
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#1F70F5] hover:bg-blue-600"
              } text-white rounded-full px-10 py-3 transition-colors`}
          >
            {isSubmitting ? "Submitting..." : "Submit Property"}
          </button>
        </div>
        <div className="h-6" />
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
      />
      <SuccessModal isVisible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default App;