import React, { useEffect } from 'react';

// Custom SVG Icons as components
const CheckIcon = () => (
  <svg 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className="w-6 h-6"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <path d="M22 4 12 14.01l-3-3" />
  </svg>
);

const CloseIcon = () => (
  <svg 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className="w-5 h-5"
  >
    <path d="M18 6 6 18" />
    <path d="m6 6 12 12" />
  </svg>
);

const SuccessModal = ({ isVisible, onClose, message = "Your form has been submitted successfully." }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <>
      {/* Mobile: Full width at bottom */}
      <div className="sm:hidden fixed bottom-0 left-0 right-0 p-4 z-50 animate-fade-up">
        <div className="bg-white rounded-t-lg shadow-lg border-t-4 border-green-500 p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="text-green-500 w-5 h-5">
                <CheckIcon />
              </span>
              <span className="font-medium text-gray-800">Success!</span>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
              aria-label="Close notification"
            >
              <CloseIcon />
            </button>
          </div>
          <p className="mt-1 text-sm text-gray-600">{message}</p>
        </div>
      </div>

      {/* Tablet: Centered toast */}
      <div className="hidden sm:block md:hidden fixed top-4 left-1/2 -translate-x-1/2 z-50 w-[80%] max-w-md animate-fade-down">
        <div className="bg-white rounded-lg shadow-lg border-l-4 border-green-500 p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="text-green-500 w-5 h-5">
                <CheckIcon />
              </span>
              <span className="font-medium text-gray-800">Success!</span>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
              aria-label="Close notification"
            >
              <CloseIcon />
            </button>
          </div>
          <p className="mt-1 text-sm text-gray-600">{message}</p>
        </div>
      </div>

      {/* Desktop: Right corner toast */}
      <div className="hidden md:block fixed top-4 right-4 z-50 w-[384px] animate-slide-left">
        <div className="bg-white rounded-lg shadow-lg border-l-4 border-green-500 p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="text-green-500 w-6 h-6">
                <CheckIcon />
              </span>
              <span className="font-medium text-gray-800">Success!</span>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
              aria-label="Close notification"
            >
              <CloseIcon />
            </button>
          </div>
          <p className="mt-2 text-gray-600">{message}</p>
        </div>
      </div>

      <style jsx>{`
        @keyframes fade-up {
          from { transform: translateY(100%); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        
        @keyframes fade-down {
          from { transform: translate(-50%, -100%); opacity: 0; }
          to { transform: translate(-50%, 0); opacity: 1; }
        }
        
        @keyframes slide-left {
          from { transform: translateX(100%); opacity: 0; }
          to { transform: translateX(0); opacity: 1; }
        }
        
        .animate-fade-up {
          animation: fade-up 0.3s ease-out;
        }
        
        .animate-fade-down {
          animation: fade-down 0.3s ease-out;
        }
        
        .animate-slide-left {
          animation: slide-left 0.3s ease-out;
        }
      `}</style>
    </>
  );
};

export default SuccessModal;