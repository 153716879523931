import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "300px"
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
  clickableIcons: true,
  keyboardShortcuts: false,
  scrollwheel: true,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

const MapComponent = ({ isLoaded, center }) => {
  const [map, setMap] = useState(null);
  const [mapError, setMapError] = useState(null);

  // Handle map load
  const onLoad = useCallback((map) => {
    try {
      // Create bounds with current center
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(center.lat, center.lng));

      // Fit bounds and set minimum zoom
      map.fitBounds(bounds);
      if (map.getZoom() > 15) {
        map.setZoom(15);
      }

      setMap(map);
      setMapError(null);
    } catch (error) {
      console.error('Error loading map:', error);
      setMapError('Failed to initialize map properly');
    }
  }, [center]);

  // Handle map unmount
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Update map center when center prop changes
  useEffect(() => {
    if (map && center) {
      try {
        map.panTo(center);
        if (map.getZoom() < 12) {
          map.setZoom(12);
        }
      } catch (error) {
        console.error('Error updating map center:', error);
        setMapError('Failed to update map location');
      }
    }
  }, [center, map]);

  if (!isLoaded) {
    return (
      <div className="w-full h-full min-h-[300px] bg-gray-100 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (mapError) {
    return (
      <div className="w-full h-full min-h-[300px] bg-red-50 flex items-center justify-center text-red-500 p-4 text-center">
        {mapError}
      </div>
    );
  }

  return (
    <div className="w-full h-full relative">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptions}
      >
        {/* Marker at the center */}
        <Marker
          position={center}
          animation={window.google.maps.Animation.DROP}
        />
      </GoogleMap>
    </div>
  );
};

// Prop validation
MapComponent.defaultProps = {
  center: { lat: 20.5937, lng: 78.9629 }, // Default to India's center
};

export default React.memo(MapComponent, (prevProps, nextProps) => {
  // Custom comparison function for memo
  return (
    prevProps.isLoaded === nextProps.isLoaded &&
    prevProps.center.lat === nextProps.center.lat &&
    prevProps.center.lng === nextProps.center.lng
  );
});