import React, { useState, useEffect } from "react";

const PropertyImages = ({ onChange, trig }) => {
  const [images, setImages] = useState([]);
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referralPhone, setReferralPhone] = useState("");

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  useEffect(() => {
    setImages([])
    setOwnerName("")
    setOwnerPhone("")
    setReferralName("")
    setReferralPhone("")
  }, [trig])

  // Effect to notify parent component of form changes
  useEffect(() => {
    if (onChange) {
      onChange({
        images,
        ownerName,
        ownerPhone,
        referralName,
        referralPhone
      });
    }
  }, [images, onChange, ownerName, ownerPhone, referralName, referralPhone]);

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <div className="w-full p-4">
        <h2 className="text-lg font-medium mb-4 sm:mb-2 ml-2 text-gray-800">
          Property Pictures
        </h2>

        <div className="border shadow-md rounded-2xl p-4 sm:p-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            {/* Upload Images Section */}
            {images.length === 0 && <div className="rounded-2xl p-4 sm:p-6 bg-blue-50 relative border border-gray-200">
              <div className="mb-4">
                <p className="text-sm font-medium text-gray-700">
                  Pictures of reception, lobby, amenities, etc.
                </p>
                <p className="text-xs text-gray-500">
                  Please add at least 5 pictures here
                </p>
              </div>
              <div className="flex justify-center items-center w-full h-48 sm:h-60 rounded-xl bg-blue-50 border-blue-300 relative">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
                <div className="flex flex-col items-center">
                  <div className="bg-blue-100 rounded-full p-3 sm:p-4 mb-3 shadow-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </div>
                  <p className="text-gray-600 font-medium">Upload images</p>
                  <p className="text-gray-400 text-xs sm:text-sm">
                    .jpg or .png files less than 5mb
                  </p>
                </div>
              </div>
            </div>}
            {/* Display uploaded images */}
            {images.length > 0 && (<div>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="hidden"
              />
              <button
                onClick={handleButtonClick}
                className="z-10 -mt-5 absolute opacity-100 cursor-pointer bg-blue-500 text-xl text-white px-[0.6rem] pb-1 rounded-full"
              >
                +
              </button>

              <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-2">
                {images.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Upload ${index + 1}`}
                      className="w-full h-24 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => setImages(images.filter((_, i) => i !== index))}
                      className="absolute top-0 right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-sm"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
            )}


            {/* Owner and Referral Details Section */}
            <div className="flex flex-col space-y-6">
              {/* Owner Details */}
              <div>
                <div className="flex items-center mb-2">
                  <h3 className="text-sm font-medium text-gray-700 bg-gray-200 px-4 py-2 rounded-full">
                    Owner Details
                  </h3>
                  <hr className="flex-grow ml-4 border-t border-gray-300" />
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Owner Name
                    </label>
                    <input
                      type="text"
                      value={ownerName}
                      onChange={(e) => setOwnerName(e.target.value)}
                      placeholder="Enter the name here"
                      className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Owner Phone number
                    </label>
                    <input
                      type="text"
                      value={ownerPhone}
                      onChange={(e) => setOwnerPhone(e.target.value)}
                      placeholder="Enter the phone number here"
                      className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              {/* Referral Details */}
              <div>
                <div className="flex items-center mb-2">
                  <h3 className="text-sm font-medium text-gray-700 bg-gray-200 px-4 py-2 rounded-full">
                    Refer Details
                  </h3>
                  <hr className="flex-grow ml-4 border-t border-gray-300" />
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Referral Name
                    </label>
                    <input
                      type="text"
                      value={referralName}
                      onChange={(e) => setReferralName(e.target.value)}
                      placeholder="Enter the name here"
                      className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Referral Phone number
                    </label>
                    <input
                      type="text"
                      value={referralPhone}
                      onChange={(e) => setReferralPhone(e.target.value)}
                      placeholder="Enter the phone number here"
                      className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyImages;