import React, { useState, useEffect, useMemo } from "react";
import MapComponent from "./MapComponent";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useJsApiLoader } from '@react-google-maps/api';

// Define libraries array outside component to prevent reloads
const libraries = ['places'];

const PropertyForm = ({ onChange, trig }) => {
  const [propertyName, setPropertyName] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 }); // Default center is India
  const [selectedCity, setSelectedCity] = useState(null);
  const [buildingName, setBuildingName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [locality, setLocality] = useState("");
  const [mapError, setMapError] = useState(null);

  useEffect(() => {
    setBuildingName("")
    setSelectedCity("")
    setCenter({ lat: 20.5937, lng: 78.9629 })
    setSelectedServices([])
    setPropertyName("")
    setStreetName("")
    setLocality("")
  }, [trig])

  // Add environment variable check
  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      console.error('Google Maps API key is not configured');
      setMapError('Google Maps configuration is missing. Please check your environment settings.');
    }
  }, []);

  const services = useMemo(() => [
    "Meal Subscription",
    "High Speed Wifi",
    "Laundry",
    "Power Backup",
    "Housekeeping",
    "Gym",
    "Washing Machine",
    "Hot Water",
    "Television",
    "Water Purifier",
    "24x7 Surveillance",
  ], []);

  // Load Maps API with error handling
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (loadError) {
      setMapError('Failed to load Google Maps. Please check your internet connection.');
      console.error('Google Maps loading error:', loadError);
    }
  }, [loadError]);

  const toggleService = (service) => {
    setSelectedServices(prev =>
      prev.includes(service)
        ? prev.filter(item => item !== service)
        : [...prev, service]
    );
  };

  const handleCityChange = async (selectedOption) => {
    try {
      setSelectedCity(selectedOption);
      if (selectedOption) {
        let addressParts = selectedOption.label.split(',');

        // Check the length of the array
        if (addressParts.length > 3) {
          // Keep all elements except the last three
          setBuildingName(addressParts[0])
        }
        if (addressParts.length > 4) {
          // Keep all elements except the last three
          setStreetName(addressParts[1])
        }
        if (addressParts.length > 5) {
          // Keep all elements except the last three
          setLocality(addressParts[2])
        }
      }

      if (selectedOption?.value?.place_id && isLoaded && window.google) {
        const geocoder = new window.google.maps.Geocoder();

        const result = await new Promise((resolve, reject) => {
          geocoder.geocode(
            { placeId: selectedOption.value.place_id },
            (results, status) => {
              if (status === "OK" && results[0]) {
                resolve(results[0]);
              } else {
                reject(new Error(`Geocoding failed: ${status}`));
              }
            }
          );
        });

        const location = result.geometry.location;
        setCenter({ lat: location.lat(), lng: location.lng() });
      }
    } catch (error) {
      console.error("Error fetching city coordinates:", error);
      setMapError("Failed to get city location. Please try again.");
    }
  };

  const fetchCurrentLocation = () => {
    if (!navigator.geolocation) {
      setMapError("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        setMapError(null);
      },
      (error) => {
        console.error("Geolocation error:", error);
        setMapError("Unable to fetch your location. Please try again or select manually.");
      },
      { timeout: 10000 }
    );
  };

  const locateButtonStyle = {
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 10,
    padding: "8px",
  };

  useEffect(() => {
    if (onChange) {
      onChange({
        propertyName,
        selectedCity,
        selectedServices,
        buildingName,
        streetName,
        locality,
        center,
      });
    }
  }, [propertyName, selectedCity, selectedServices, buildingName, streetName, locality, center, onChange]);

  // Loading state
  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Error state
  if (loadError) {
    return (
      <div className="bg-red-50 text-red-500 p-4 rounded-lg">
        Failed to load Google Maps. Please check your configuration or try refreshing the page.
      </div>
    );
  }

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <div className="p-4 w-full">
        {/* Header */}
        <div className="flex items-center mb-6 border-b-2 border-gray-200 pb-4 sm:pb-8">
          <div className="flex items-center space-x-2">
            <img src="/logo.png" alt="Logo" className="" />
            <h2>
              <span className="font-bold">supernest</span>.ai
            </h2>
          </div>
          <h1 className="text-lg sm:text-xl font-semibold ml-4 sm:ml-12">
            Nest your PG
          </h1>
        </div>

        {mapError && (
          <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-4">
            {mapError}
          </div>
        )}

        <div>
          <h2 className="text-base sm:text-lg ml-2 mb-2 text-[14px] font-[500]">
            Property Information
          </h2>
        </div>
        <div className="border shadow-md rounded-2xl p-4 sm:p-6">
          {/* Property Information */}
          <div className="border-b pb-4 mb-4 sm:pb-6 sm:mb-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Property Name
                </label>
                <input
                  type="text"
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                  placeholder="Enter your name here..."
                  className="mt-2 block w-full rounded-lg border border-gray-300 shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  City
                </label>
                <GooglePlacesAutocomplete
                  selectProps={{
                    value: selectedCity,
                    onChange: handleCityChange,
                    placeholder: "Select your city here...",
                    isClearable: true,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        padding: "0.25rem",
                        borderRadius: "0.5rem",
                        borderColor: "#D1D5DB",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      }),
                    },
                  }}
                  apiOptions={{ componentRestrictions: { country: "in" } }}
                />
              </div>
            </div>
          </div>

          {/* Services */}
          <div className="mb-4 sm:mb-6">
            <h2 className="text-base sm:text-lg font-medium mb-2 sm:mb-4">
              Services
            </h2>
            <div className="flex flex-wrap gap-2">
              {services.map((service, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => toggleService(service)}
                  className={`px-4 py-2 rounded-full border transition-colors ${selectedServices.includes(service)
                    ? "bg-indigo-500 text-white"
                    : "text-gray-700 hover:bg-gray-50"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  {service}
                </button>
              ))}
            </div>
          </div>

          {/* Property Address */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            <div className="rounded-md overflow-hidden h-72 sm:h-full relative">
              <button
                style={locateButtonStyle}
                onClick={fetchCurrentLocation}
                className="sm:top-4 sm:right-4 hover:bg-gray-50 transition-colors"
                type="button"
              >
                <span
                  className="material-icons"
                  style={{ fontSize: "24px", color: "#007BFF" }}
                >
                  my_location
                </span>
              </button>

              <MapComponent
                isLoaded={isLoaded}
                center={center}
              />
            </div>
            <div>
              <div className="p-6 rounded-xl shadow-md">
                <p className="text-gray-500">
                  {selectedCity?.label || "Select a city"}
                </p>
              </div>

              <div className="mt-4 flex items-center">
                <h3 className="text-sm font-medium text-black bg-gray-200 px-4 py-2 rounded-full">
                  COMPLETE ADDRESS
                </h3>
                <hr className="flex-grow ml-4 border-t border-gray-300" />
              </div>
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Building Name/Door Number
                  </label>
                  <input
                    type="text"
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                    className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Street Name
                  </label>
                  <input
                    type="text"
                    value={streetName}
                    onChange={(e) => setStreetName(e.target.value)}
                    className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Locality
                  </label>
                  <input
                    type="text"
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}
                    className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PropertyForm);