import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const createProperty = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/discovery/properties`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to submit property';
  }
};